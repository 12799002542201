import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
 
  {
    path: '/',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/add-project',
    name: 'AddProjects',
    component: () => import('../views/AddProject.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component : () => import('../views/Projects')
  },
  {
    path: '/view-project/:id',
    name: 'ViewProject',
    component : () => import('../views/ViewProject')
  },
  {
    path: '/edit-project/:id',
    name: 'EditProject',
    component : () => import('../views/EditProject')
  }
]

const router = new VueRouter({
  routes
})

export default router
