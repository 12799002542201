<template>
  <v-app>
    <v-app-bar app color="grey darken-4" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="140"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <router-view v-if="loggedIn" />
      <div v-else>
        <v-container class="mt-10">
          <center>
            <h1>LOGIN</h1>
            <p>Please use your 6 digit pin to continue.</p>
            <v-otp-input length="6" v-model="pin" style="width: 300px;"></v-otp-input>
            <v-btn @click="login" large class="mt-10">Login</v-btn>
          </center>
        </v-container>
        
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    loggedIn: false,
    pin : ""
  }),
  methods: {
    login() {
      if (this.pin == 114764) {
        this.loggedIn = true;
      } else {
        alert("Wrong Pincode");
      }
    },
  },
};
</script>


<style>
.row {
  margin: 0px;
}
html,
body {
  background: #000 !important;
  height: 100%;
  font-family: "Blinker", sans-serif !important;
}
.v-application {
  font-family: "Blinker", sans-serif !important;
}
p {
  font-family: "Blinker", sans-serif !important;
}
h1 {
  font-family: "Blinker", sans-serif !important;
}
.display-1 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-1 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-2 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-3 {
  font-family: "Blinker", sans-serif !important;
}
.v-application .display-4 {
  font-family: "Blinker", sans-serif !important;
}
.theme--dark.v-application {
  background: #000;
}
.toast-title {
  font-family: "Blinker" !important;
  font-weight: 700;
}
.toast-message {
  font-family: "Blinker" !important;
}
.f-alfa {
  font-family: "Alfa Slab One", serif !important;
  letter-spacing: 1px;
  font-weight: 500;
}
.row {
  padding: 0px !important;
  margin: 0px !important;
}

.v-text-field__details {
  display: none !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.fw-bold {
  font-weight: bold !important;
}
.vertical-text {
  writing-mode: vertical-rl; /* This property makes text vertical */
  transform: rotate(
    180deg
  ); /* Rotate the text 180 degrees to make it upright */
  position: fixed;
  bottom: 20px;
  left: 20px;
}

@media (min-width: 960px) {
  .container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media only screen and (max-width: 575.98px) {
  /* Styles for extra small screens */
  .f-alfa {
    font-size: 14pt !important;
  }
}

.v-toolbar__extension {
  height: 5px !important;
}
</style>