import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme : {
        dark : true,
        default : 'Blinker, sans-serif',
        themes : {
            dark : {
                primary : "#3f51b5",
                accent : "#FCD9B8",
                secondary: "#292C35"
                
            },
            fonts : {
                body : 'Blinker, sans-serif',
                heading : 'Blinker, sans-serif',
            },
            
        }
    }
});
